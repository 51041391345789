//@author: devin
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25Util } from "../../../util/s25-util";

export class GenericDropdownUtil {
    public static extractItems(data: any): DropDownItem[] {
        if (data) {
            return data.map((obj: any) => {
                if (obj.itemId && !obj.val) {
                    obj.val = obj.itemId;
                }

                if (obj.itemName && !obj.txt) {
                    obj.txt = obj.itemName;
                }

                if (obj.val && !obj.itemId && S25Util.isNumeric(obj.val)) {
                    obj.itemId = obj.val;
                }

                if (obj.txt && !obj.itemName) {
                    obj.itemName = obj.txt;
                }

                return obj;
            });
        }
    }
}
