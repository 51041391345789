//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaType } from "../../../pojo/SearchCriteriaI";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { DropdownDataUtil } from "../dropdown.data.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { SearchCriteriaContext } from "../../../services/search/search-criteria/search.criteria.context";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { jSith } from "../../../util/jquery-replacement";

@TypeManagerDecorator("s25-ng-dropdown-multi-search-criteria")
@Component({
    selector: "s25-ng-dropdown-multi-search-criteria",
    template: `
        @if (isNonEmptyBean(this.items)) {
            <s25-ng-generic-multiselect-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="this.hasFav"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="this.searchEnabled"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="this.placeholder"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                [alwaysShowDone]="this.alwaysShowDone"
                (chosenChange)="onChosenChange($event)"
                [onDone]="onDone"
                [hasDone]="hasDone"
                (done)="done.emit($event)"
            >
            </s25-ng-generic-multiselect-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DropdownMultiSearchCriteriaComponent extends S25DropdownAbstract implements OnInit {
    @Input() type: SearchCriteriaType["type"];
    @Input() chosen: DropDownItem[];
    @Input() onDone?: Function; // Exists for angularJS integration
    @Input() alwaysShowDone?: boolean = true;
    @Input() placeholder?: string;
    @Input() hasDone: boolean;

    @Output() done = new EventEmitter<DropDownItem[]>();

    serviceMethod: Function; // temporary hold for context.serviceMethod when switched for custom GET request (security group category filter)

    static MIN_ITEMS_FOR_SEARCH: number = 10;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }
    isNonEmptyBean = S25Util.isNonEmptyBean;

    ngOnInit() {
        let context = SearchCriteriaContext.Context[this.type];
        if (context) {
            if (!this.placeholder) this.placeholder = "Select from " + context.title;
            this.hasFav = S25Util.toBool(context.showFavorite);

            if (this.chosen && this.chosen.length && this.chosen.length > 0) {
                this.chosen.forEach((chosen) => {
                    chosen.itemTypeId = context.itemTypeId;
                });
            }

            let p: Promise<any>;
            let DDItems: DropDownItem[] = [];

            if (context.expensiveSearch) {
                this.searchEnabled = true;
                p = context
                    .serviceMethod(
                        //fetch favorites
                        -999, //cacheId
                        0, //pageNum
                        999, //pageSize
                        null, //filter
                        true, //filterFavorites
                        null, //domainFilter
                        this.customFilterValue, //permanent customFilterValue
                    )
                    .then((data: any) => {
                        this.zone.run(() => {
                            this.staticItems = this.staticItems || [];
                            let staticCopy = [].concat(this.staticItems);
                            let favorites = SearchCriteriaService.extractItems(data);

                            jSith.forEach(favorites, (favKey, fav) => {
                                let inStatic = false;

                                jSith.forEach(
                                    staticCopy,
                                    (itemKey, item) => {
                                        if (parseInt(fav.itemId) === parseInt(item.itemId)) {
                                            inStatic = true;
                                            item.favorite = "T";
                                            return true;
                                        }
                                    },
                                    false,
                                    true,
                                );

                                if (!inStatic) {
                                    if (staticCopy.length && staticCopy[0].grp) {
                                        fav.grp = "Starred";
                                    }
                                    this.staticItems.push(fav);
                                }
                            });

                            this.serverSide = DropdownDataUtil.getServerSideModel(
                                context.serviceMethod,
                                this.elementRef.nativeElement,
                                this.customFilterValue,
                            );
                            DDItems = S25Util.deepCopy(this.staticItems);
                        });
                    });
            } else {
                //if useSecurity is set, list of event types is filtered to those allowed by rose config and preference config (used for writing new event type)
                if (context.customFilterMethod) {
                    this.serviceMethod = context.serviceMethod;
                    context.serviceMethod = context.customFilterMethod;
                } // security group categories

                p = context
                    .serviceMethod(S25Util.toBool(this.useSecurity), S25Util.toBool(this.activeOnly))
                    .then((data: DropDownItem[]) => {
                        this.zone.run(() => {
                            let items = SearchCriteriaService.extractItems(data);
                            this.staticItems = items.filter((obj: DropDownItem) => {
                                return obj.favorite === "T";
                            });
                            DDItems = items;
                            this.searchEnabled =
                                DDItems.length > S25DropdownMultiSearchCriteriaComponent.MIN_ITEMS_FOR_SEARCH;
                        });
                    });
            }

            p.then(() => {
                if (context.customFilterMethod) context.serviceMethod = this.serviceMethod;
                this.items = DDItems;
                this.onLoad && this.onLoad();
                this.cd.detectChanges();
            });
        }
    }
}
