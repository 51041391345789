import { Api } from "../../api/api";
import { S25DropdownPaginatedComponent } from "./s25.dropdown.paginated.component";

export class DropdownApi extends Api {
    static enterSelect(relativeElem: HTMLElement) {
        return DropdownApi.callApiFn(relativeElem, "s25-dropdown-paginated", "enterSelect");
    }

    static search(relativeElem: HTMLElement, data: string) {
        return DropdownApi.callApiFn(
            relativeElem,
            "s25-dropdown-paginated",
            null,
            null,
            (comp: S25DropdownPaginatedComponent) => {
                if (comp.model.searchEnabled) {
                    comp.search = data;
                }
            },
        );
    }

    static arrow(relativeElem: HTMLElement, dir: "up" | "down" | "leftRight") {
        return DropdownApi.callApiFn(relativeElem, "s25-dropdown-paginated", "arrow", dir);
    }

    static open(relativeElem: HTMLElement) {
        return DropdownApi.callApiFn(relativeElem, "s25-dropdown-paginated", "open");
    }

    static close(relativeElem: HTMLElement, id?: any) {
        return DropdownApi.callApiFn(
            relativeElem,
            "s25-dropdown-paginated",
            null,
            null,
            (comp: S25DropdownPaginatedComponent) => {
                if (id === comp.id) {
                    //if id passed to close event, it means it is a call to close all OTHERS except for the calling dropdown-paginated...
                    return; //do not close self
                }
                comp.close();
            },
        );
    }

    static refresh(relativeElem: HTMLElement, items: any) {
        let defaultPrevented = false;
        return DropdownApi.callApiFn(
            relativeElem,
            "s25-dropdown-paginated",
            null,
            null,
            (comp: S25DropdownPaginatedComponent) => {
                if ((!items.id || items.id === comp.id) && !defaultPrevented) {
                    defaultPrevented = true;
                    comp.model.items = items;
                    comp.setupItems(comp.model.items);
                    //adds display newly added items to dropdown - eg. New search is created
                    comp.currResults = [].concat(comp.model.items);
                    comp.resetArrows();
                }
            },
        );
    }
}
