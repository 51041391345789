import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { DropdownDataUtil } from "../dropdown.data.util";
import { Item } from "../../../pojo/Item";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { Bind } from "../../../decorators/bind.decorator";
import Ids = Item.Ids;
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-dropdown-multi-relationships")
@Component({
    selector: "s25-ng-dropdown-multi-relationships",
    template: `
        @if (items) {
            <s25-ng-generic-multiselect-dropdown
                [onSelect]="onSelect"
                [onDone]="onDone"
                [items]="items"
                [autoOpen]="autoOpen"
                [alwaysOpen]="alwaysOpen"
                [hideSearch]="hideSearch"
                [focusFirst]="focusFirst"
                [searchEnabled]="true"
                [apiBean]="apiBean"
                [serverSide]="serverSide"
                [placeholder]="placeholder"
                [resetSelectedOnCleanup]="resetSelectedOnCleanup"
                [hasDone]="hasDone"
            >
                <div topContent>
                    Relationship:
                    <select [(ngModel)]="chosenRelationship" (click)="$event.stopPropagation()">
                        @for (ship of relationships[type]; track ship) {
                            <option [ngValue]="ship">{{ ship.itemName }}</option>
                        }
                    </select>
                </div>
            </s25-ng-generic-multiselect-dropdown>
        }
    `,
})
export class S25DropdownMultiRelationshipsComponent extends S25DropdownAbstract implements OnInit {
    @Input() type: Ids.Location | Ids.Resource;
    @Input() placeholder: string = "Search";
    @Input() hasDone: boolean;

    @Output() done = new EventEmitter<ObjectsAndRelationship>();

    relationships: Record<Ids.Location | Ids.Resource, DropDownItem[]> = {
        [Ids.Location]: [
            { itemId: 4, itemName: "Also Assign" },
            { itemId: 3, itemName: "Blocked By" },
            { itemId: 2, itemName: "Close To" },
            { itemId: 5, itemName: "Divides Into" },
            { itemId: 1, itemName: "Subdivision Of", default: true },
        ],
        [Ids.Resource]: [
            { itemId: 9, itemName: "Also Assign" },
            { itemId: 8, itemName: "Substitute With", default: true },
        ],
    };
    chosenRelationship: DropDownItem;

    constructor(private elementRef: ElementRef) {
        super();
    }

    async ngOnInit() {
        this.chosenRelationship = this.relationships[this.type].find((ship) => ship.default);
        this.items = [];
        const method = this.type === Ids.Location ? "getLocations" : "getResources";
        this.serverSide = DropdownDataUtil.getServerSideModel(method, this.elementRef.nativeElement, null);
    }

    @Bind
    onDone({ items }: { items: DropDownItem[] }) {
        const relationship = { id: Number(this.chosenRelationship?.itemId), name: this.chosenRelationship?.itemName };
        const objects = items.map((item) => ({ id: Number(item.itemId), name: item.itemName }));
        this.done.emit({ relationship, objects });
    }
}

export type ObjectsAndRelationship = {
    relationship: { id: number; name: string };
    objects: { id: number; name: string }[];
};
