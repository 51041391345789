//@author: devin
import { ServerSidePagination } from "../../pojo/ServerSidePagination";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { GenericDropdownUtil } from "./generic/generic.dropdown.util";
import { jSith } from "../../util/jquery-replacement";
import { InfiniteScrollApi } from "../s25-infinite-scroll/infinite.scroll.api";
import { S25Util } from "../../util/s25-util";

export class DropdownDataUtil {
    public static getServerSideModel(
        searchCriteriaMethod: string | Function,
        element: HTMLElement,
        customFilterValue: any,
    ): ServerSidePagination {
        let searchPromises: Promise<any>[] = [];
        let searchCriteriaService: any = SearchCriteriaService;
        let serviceMethod: Function = S25Util.isString(searchCriteriaMethod)
            ? searchCriteriaService[searchCriteriaMethod as string]
            : searchCriteriaMethod;

        let ret: ServerSidePagination = {
            searchPromises: searchPromises,
            cacheId: 0,
            page: 0,
            pageCount: 0,
            pageSize: 100,

            reset: () => {
                ret.pageCount = 0;
                ret.cacheId = 0;
                ret.page = 0;
            },

            hasMore: () => {
                return ret.page < ret.pageCount;
            },

            scrollF: () => {
                return serviceMethod(ret.cacheId, ret.page, ret.pageSize).then((data: any) => {
                    if (data && data.searchCriteria && data.searchCriteria.item && data.searchCriteria.item.length) {
                        ret.page++; //increment page number
                        return GenericDropdownUtil.extractItems(data.searchCriteria.item);
                    }
                });
            },

            search: (filter) => {
                InfiniteScrollApi.clear(element); //stop listening to scroll bc we're totally changing the items to a new page set
                ret.reset();
                let promise = serviceMethod(ret.cacheId, ret.page, ret.pageSize, filter, null, null, customFilterValue);
                ret.searchPromises.push(promise);
                return promise.then((data: any) => {
                    let len = ret.searchPromises.length;
                    if (len > 0 && promise === ret.searchPromises[len - 1]) {
                        ret.resetPaginationToNewData(data);
                        setTimeout(() => {
                            //after new items are all handled and in DOM, re-enable scrolling and even start paging if need-be
                            InfiniteScrollApi.forceScrollAction(element);
                        }, 250);
                        return (
                            data && data.searchCriteria && GenericDropdownUtil.extractItems(data.searchCriteria.item)
                        );
                    } else {
                        setTimeout(() => {
                            //re-enable scrolling and even start paging if need-be
                            InfiniteScrollApi.forceScrollAction(element);
                        }, 250);
                        return jSith.when("oldPromise"); //indicate to caller that old promise returned (we should not change dropdown data)
                    }
                });
            },

            resetPaginationToNewData: (data: any): void => {
                if (data && data.searchCriteria) {
                    ret.pageCount = (data && data.searchCriteria.page_count) || 0;
                    ret.cacheId = (data && data.searchCriteria.cache_id) || 0;
                } else {
                    ret.pageCount = 0;
                    ret.cacheId = 0;
                }

                ret.page = 1;
            },
        };

        return ret;
    }
}
